import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Popover } from "react-tiny-popover";
import { FONT, MAIN_COLOR, STITCHING_COLOUR } from "../utils/const";
import logoSmall from "../../logos/logo_stitched.png";
import { useNavigate } from "react-router-dom";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${MAIN_COLOR};
  height: 120px;
  font-family: ${FONT};
  padding-left: 1.25rem;
  border-bottom: 2px dashed ${STITCHING_COLOUR};
  padding-right: 1.5rem;
`;

const Tab = styled.div<{
  backgroundColor: string;
  color: string;
  disabled: boolean;
}>`
  margin-left: 30px;
  padding: 0.75rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  font-family: Inconsolata, sans-serif;
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border: 2px dashed ${STITCHING_COLOUR};
  box-shadow: 0 0 0 8px ${({ backgroundColor }) => backgroundColor},
    8px 7px 14px -8px rgba(0, 0, 0, 0.1);
  text-align: center;
  min-width: 100px;
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
        `
      : css`
          cursor: pointer;
        `};
`;

const SubTab = styled.div<{ backgroundColor: string; color: string }>`
  padding: 0.75rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  font-family: Inconsolata, sans-serif;
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border: 2px dashed ${STITCHING_COLOUR};
  box-shadow: 0 0 0 8px ${({ backgroundColor }) => backgroundColor},
    8px 7px 14px -8px rgba(0, 0, 0, 0.1);
  text-align: center;
  min-width: 100px;
  cursor: pointer;
`;

const MadeInAus = styled.div<{ backgroundColor: string; color: string }>`
  margin-top: 172px;
  margin-left: 25px;
  padding: 1rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  font-family: Inconsolata, sans-serif;
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border: 2px dashed ${STITCHING_COLOUR};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 0 0 4px ${({ backgroundColor }) => backgroundColor},
    8px 7px 14px -8px rgba(0, 0, 0, 0.1);
  text-align: center;
  min-width: 100px;
`;

const StitchedLogo = styled.div`
  margin-top: 120px;
  padding: 2px;
  background: black;
  color: #fff;
  font-size: 21px;
  font-weight: bold;
  line-height: 1.3em;
  border: 1px dashed ${STITCHING_COLOUR};
  height: 200px;
  width: 200px;
  border-radius: 200px;
  box-shadow: 0 0 0 4px black, 4px 2px 6px 4px rgba(10, 10, 0, 0.5);
  font-weight: normal;
`;

export function Header() {
  const navigate = useNavigate();
  const [shopPopoverOpen, setShopPopover] = useState<boolean>(false);

  return (
    <HeaderWrapper>
      <StitchedLogo>
        <img
          style={{
            width: "200px",
            height: "200px",
          }}
          src={logoSmall}
          alt="My Image"
        />
      </StitchedLogo>
      <Tab
        color={STITCHING_COLOUR}
        backgroundColor="black"
        disabled={false}
        onClick={() => {
          navigate("/");
        }}
      >
        Home
      </Tab>
      <Popover
        isOpen={shopPopoverOpen}
        positions={["bottom"]} // preferred positions by priority
        content={
          <div>
            <SubTab
              color={STITCHING_COLOUR}
              backgroundColor="black"
              onClick={() => {
                navigate("shop/keyboards");
              }}
              onMouseEnter={() => {
                setShopPopover(true);
              }}
              onMouseLeave={() => {
                setShopPopover(false);
              }}
            >
              Keyboards
            </SubTab>
          </div>
        }
      >
        <Tab
          color={STITCHING_COLOUR}
          backgroundColor="black"
          disabled={false}
          onMouseEnter={() => {
            setShopPopover(true);
          }}
          onMouseLeave={() => {
            setShopPopover(false);
          }}
        >
          Shop
        </Tab>
      </Popover>
      <Tab color={STITCHING_COLOUR} backgroundColor="black" disabled={true}>
        Blog (coming soon)
      </Tab>
      <Tab color={STITCHING_COLOUR} backgroundColor="black" disabled={true}>
        Guides (coming soon)
      </Tab>
      <div style={{ flex: 1 }} />
      <MadeInAus color="#f8ad33" backgroundColor="#008266">
        Australian Made
      </MadeInAus>
    </HeaderWrapper>
  );
}
