import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-top: auto;
`;

export const HomePage = () => {
  return (
    <Wrapper>
      <section>
        <p>Your one-stop shop for unique, Australian made products.</p>
      </section>
      <section>
        <h2>Our Collections</h2>
        <div>
          <h3>Keyboards</h3>
          <p>Expertly crafted keyboards designed for enthusiasts.</p>
        </div>
        <div>
          <h3>Camping Equipment</h3>
          <p>Durable camping gear for the great Australian outdoors.</p>
        </div>
        <div>
          <h3>Software</h3>
          <p>Reliable and innovative software solutions.</p>
        </div>
        <div>
          <h3>Electronics Projects</h3>
          <p>
            Various electronics projects with a touch of Australian ingenuity.
          </p>
        </div>
      </section>
      <FooterWrapper>
        <div>© Andrew Gierens 2023</div>
        <a href="privacy">Privacy Policy</a>
        <div>ABN: 99 231 056 236</div>
      </FooterWrapper>
    </Wrapper>
  );
};
