import React from "react";

interface HorizontalRuleProps {
  color?: string;
  height?: string;
  width?: string;
}

const HorizontalRule: React.FC<HorizontalRuleProps> = ({
  color = "black",
  height = "1px",
  width = "100%",
}) => {
  return (
    <div
      style={{
        backgroundColor: color,
        height: height,
        width: width,
        marginBottom: "0.8rem",
      }}
    ></div>
  );
};

export default HorizontalRule;
