import React from "react";
import styled from "styled-components";
import { STITCHING_COLOUR } from "../utils/const";
import HorizontalRule from "../components/hr";

interface Product {
  id: number;
  name: string;
  description: string;
  image: string;
  buttonId: string;
}

interface StorePageProps {
  products: Product[];
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StripeButtonWrapper = styled.div`
  padding: 1rem;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-top: auto;
`;

const Card = styled.div`
  padding: 1.2rem;
  font-family: Inconsolata, sans-serif;
  background: black;
  color: ${({ color }) => color};
  border: 2px dashed ${STITCHING_COLOUR};
  box-shadow: 0 0 0 8px black, 8px 7px 14px -8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
`;

const ProductHeader = styled.div`
  font-size: 24px;
  padding-bottom: 1rem;
`;

const ProductDescription = styled.div`
  font-size: 16px;
  padding-bottom: 1rem;
`;

const ProductImage = styled.img`
  width: 280px;
  padding-bottom: 1rem;
`;

export const StorePage: React.FC<StorePageProps> = ({ products }) => {
  return (
    <Wrapper>
      <ProductWrapper>
        {products.map((product) => (
          <StripeButtonWrapper key={product.id}>
            <Card>
              <ProductImage src={product.image} />
              <ProductHeader>{product.name}</ProductHeader>
              <HorizontalRule color="#ccc" height="1px" width="100%" />
              <ProductDescription>{product.description}</ProductDescription>
              <stripe-buy-button
                buy-button-id={product.buttonId}
                publishable-key="pk_test_51Nr7bZLpD6CamZd4zPbd4UU0okaFZ6F8W5JBbPNNMWiwGQrAOb6T1RcIHhwHt7gqAPUEzOBbgTsNgpv0QmA25de600eIIgGx0F"
              ></stripe-buy-button>
            </Card>
          </StripeButtonWrapper>
        ))}
      </ProductWrapper>
      <FooterWrapper>
        <div>© Andrew Gierens 2023</div>
        <a href="privacy">Privacy Policy</a>
        <div>ABN: 99 231 056 236</div>
      </FooterWrapper>
    </Wrapper>
  );
};
