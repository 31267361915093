import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  padding-top: 5rem;
  margin-top: auto;
`;

export const PrivacyPolicyPage = () => {
  return (
    <Wrapper>
      <ProductWrapper>
        <div>
          <h1>Privacy Policy</h1>
          <p>Last updated: September 17, 2023</p>

          <h2>Personal Information Collection and Use</h2>
          <p>
            We may collect personal information such as your name, email
            address, and payment details when you use our services. This
            information is used to provide and improve our services, and to
            communicate with you regarding updates, promotions, and other
            information relevant to our services.
          </p>

          <h2>Log Data</h2>
          <p>
            We may collect information that your browser sends whenever you
            visit our website ("Log Data"). This Log Data may include
            information such as your computer's IP address, browser type,
            browser version, the pages of our site that you visit, the time and
            date of your visit, the time spent on those pages and other
            statistics.
          </p>

          <h2>Cookies</h2>
          <p>
            We use "cookies" to collect information. You can instruct your
            browser to refuse all cookies or to indicate when a cookie is being
            sent. However, if you do not accept cookies, you may not be able to
            use some portions of our site.
          </p>

          <h2>Service Providers</h2>
          <p>
            We may employ third-party companies and individuals to facilitate
            our services, to provide the services on our behalf, or to assist us
            in analyzing how our services are used. These third parties have
            access to your personal information only to perform these tasks on
            our behalf and are obligated not to disclose or use it for any other
            purpose.
          </p>

          <h2>Security</h2>
          <p>
            The security of your personal information is important to us, but
            remember that no method of transmission over the Internet, or method
            of electronic storage, is 100% secure. While we strive to use
            commercially acceptable means to protect your personal information,
            we cannot guarantee its absolute security.
          </p>

          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            You are advised to review this Privacy Policy periodically for any
            changes.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us.
          </p>
        </div>
      </ProductWrapper>
    </Wrapper>
  );
};
