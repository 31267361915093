import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Header } from "./components/header";
import { FONT, MAIN_COLOR, STITCHING_COLOUR } from "./utils/const";
import { PrivacyPolicyPage } from "./pages/privacyPolicy";
import { HomePage } from "./pages/home";
import { StorePage } from "./components/storePage";

import pcbImage from "../images/pcb.jpg";

const Wrapper = styled.div`
  height: 100vh;
`;

const MainContent = styled.div`
  background: ${MAIN_COLOR};
  padding-top: 3rem;
  padding-left: 18rem;
  padding-right: 18rem;
  font-family: ${FONT};
  color: ${STITCHING_COLOUR};
  font-size: 1.5rem;
`;

const App = () => {
  return (
    <Wrapper>
      <Router>
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <MainContent>
                <HomePage />
              </MainContent>
            }
          />
          {/* <Route path="/blog" element={<MainContent>blog</MainContent>} /> */}
          <Route
            path="/shop/keyboards"
            element={
              <MainContent>
                <StorePage
                  products={[
                    {
                      id: 1,
                      name: "Solarus Keyboard - PCB ONLY",
                      description:
                        "In the box, you'll find the Solarus Keyboard PCB, the heart of your custom keyboard project. The keyboard utilises QMK firmware. Switches, electronics, and keycaps are not included in this purchase.",
                      image: pcbImage,
                      buttonId: "buy_btn_1Nr89oLpD6CamZd4lZtLXYXh",
                    },
                  ]}
                />
              </MainContent>
            }
          />
          {/* <Route path="/guides" element={<MainContent>guides</MainContent>} /> */}
          <Route
            path="/privacy"
            element={
              <MainContent>
                <PrivacyPolicyPage />
              </MainContent>
            }
          />
        </Routes>
      </Router>
    </Wrapper>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
